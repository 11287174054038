@font-face {
  font-family: KaTeX_AMS;
  src: url("KaTeX_AMS-Regular.4cd18550.woff2") format("woff2"), url("KaTeX_AMS-Regular.0ed69bcc.woff") format("woff"), url("KaTeX_AMS-Regular.250082d1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Caligraphic;
  src: url("KaTeX_Caligraphic-Bold.7d84fcea.woff2") format("woff2"), url("KaTeX_Caligraphic-Bold.8b718944.woff") format("woff"), url("KaTeX_Caligraphic-Bold.5d7c4d00.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Caligraphic;
  src: url("KaTeX_Caligraphic-Regular.7e34eae2.woff2") format("woff2"), url("KaTeX_Caligraphic-Regular.ab0f4493.woff") format("woff"), url("KaTeX_Caligraphic-Regular.d4de5f30.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Fraktur;
  src: url("KaTeX_Fraktur-Bold.db0fea8b.woff2") format("woff2"), url("KaTeX_Fraktur-Bold.75fe0c6c.woff") format("woff"), url("KaTeX_Fraktur-Bold.4d449c5d.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Fraktur;
  src: url("KaTeX_Fraktur-Regular.7a216293.woff2") format("woff2"), url("KaTeX_Fraktur-Regular.1cce75f1.woff") format("woff"), url("KaTeX_Fraktur-Regular.ab0bff81.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Main;
  src: url("KaTeX_Main-Bold.88cef7b1.woff2") format("woff2"), url("KaTeX_Main-Bold.08709b09.woff") format("woff"), url("KaTeX_Main-Bold.95836a30.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Main;
  src: url("KaTeX_Main-BoldItalic.c09d0cf1.woff2") format("woff2"), url("KaTeX_Main-BoldItalic.d122a512.woff") format("woff"), url("KaTeX_Main-BoldItalic.26f3c4f3.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: KaTeX_Main;
  src: url("KaTeX_Main-Italic.de0e580d.woff2") format("woff2"), url("KaTeX_Main-Italic.66e02c4c.woff") format("woff"), url("KaTeX_Main-Italic.c6e95937.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: KaTeX_Main;
  src: url("KaTeX_Main-Regular.e2dbd876.woff2") format("woff2"), url("KaTeX_Main-Regular.5a82f7eb.woff") format("woff"), url("KaTeX_Main-Regular.cd9ba532.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Math;
  src: url("KaTeX_Math-BoldItalic.6cf39e99.woff2") format("woff2"), url("KaTeX_Math-BoldItalic.b0ed6322.woff") format("woff"), url("KaTeX_Math-BoldItalic.df285d77.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: KaTeX_Math;
  src: url("KaTeX_Math-Italic.0470e72d.woff2") format("woff2"), url("KaTeX_Math-Italic.cfffd3c0.woff") format("woff"), url("KaTeX_Math-Italic.ffea941f.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: KaTeX_SansSerif;
  src: url("KaTeX_SansSerif-Bold.b444b1f5.woff2") format("woff2"), url("KaTeX_SansSerif-Bold.8e1222db.woff") format("woff"), url("KaTeX_SansSerif-Bold.67ea3185.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_SansSerif;
  src: url("KaTeX_SansSerif-Italic.6a451fb7.woff2") format("woff2"), url("KaTeX_SansSerif-Italic.fb683703.woff") format("woff"), url("KaTeX_SansSerif-Italic.75326218.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: KaTeX_SansSerif;
  src: url("KaTeX_SansSerif-Regular.e52904c3.woff2") format("woff2"), url("KaTeX_SansSerif-Regular.94fae992.woff") format("woff"), url("KaTeX_SansSerif-Regular.51b8a34d.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Script;
  src: url("KaTeX_Script-Regular.9f85c0f7.woff2") format("woff2"), url("KaTeX_Script-Regular.6205c9f9.woff") format("woff"), url("KaTeX_Script-Regular.964126b9.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Size1;
  src: url("KaTeX_Size1-Regular.263f0826.woff2") format("woff2"), url("KaTeX_Size1-Regular.679bf7b5.woff") format("woff"), url("KaTeX_Size1-Regular.9e7bb2db.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Size2;
  src: url("KaTeX_Size2-Regular.7ddce1f2.woff2") format("woff2"), url("KaTeX_Size2-Regular.5c5b0f28.woff") format("woff"), url("KaTeX_Size2-Regular.16a5807b.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Size3;
  src: url("KaTeX_Size3-Regular.8e605720.woff2") format("woff2"), url("KaTeX_Size3-Regular.2a93ab93.woff") format("woff"), url("KaTeX_Size3-Regular.347ba5e0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Size4;
  src: url("KaTeX_Size4-Regular.595ce6e5.woff2") format("woff2"), url("KaTeX_Size4-Regular.2488fb45.woff") format("woff"), url("KaTeX_Size4-Regular.f41b14d6.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: KaTeX_Typewriter;
  src: url("KaTeX_Typewriter-Regular.21beea8e.woff2") format("woff2"), url("KaTeX_Typewriter-Regular.662fc5b4.woff") format("woff"), url("KaTeX_Typewriter-Regular.ffbe21f7.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.katex {
  text-indent: 0;
  text-rendering: auto;
  font: 1.21em / 1.2 KaTeX_Main, Times New Roman, serif;
}

.katex * {
  border-color: currentColor;
  -ms-high-contrast-adjust: none !important;
}

.katex .katex-version:after {
  content: "0.16.11";
}

.katex .katex-mathml {
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.katex .katex-html > .newline {
  display: block;
}

.katex .base {
  white-space: nowrap;
  width: min-content;
  display: inline-block;
  position: relative;
}

.katex .strut {
  display: inline-block;
}

.katex .textbf {
  font-weight: bold;
}

.katex .textit {
  font-style: italic;
}

.katex .textrm {
  font-family: KaTeX_Main;
}

.katex .textsf {
  font-family: KaTeX_SansSerif;
}

.katex .texttt {
  font-family: KaTeX_Typewriter;
}

.katex .mathnormal {
  font-family: KaTeX_Math;
  font-style: italic;
}

.katex .mathit {
  font-family: KaTeX_Main;
  font-style: italic;
}

.katex .mathrm {
  font-style: normal;
}

.katex .mathbf {
  font-family: KaTeX_Main;
  font-weight: bold;
}

.katex .boldsymbol {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: bold;
}

.katex .amsrm, .katex .mathbb, .katex .textbb {
  font-family: KaTeX_AMS;
}

.katex .mathcal {
  font-family: KaTeX_Caligraphic;
}

.katex .mathfrak, .katex .textfrak {
  font-family: KaTeX_Fraktur;
}

.katex .mathboldfrak, .katex .textboldfrak {
  font-family: KaTeX_Fraktur;
  font-weight: bold;
}

.katex .mathtt {
  font-family: KaTeX_Typewriter;
}

.katex .mathscr, .katex .textscr {
  font-family: KaTeX_Script;
}

.katex .mathsf, .katex .textsf {
  font-family: KaTeX_SansSerif;
}

.katex .mathboldsf, .katex .textboldsf {
  font-family: KaTeX_SansSerif;
  font-weight: bold;
}

.katex .mathitsf, .katex .textitsf {
  font-family: KaTeX_SansSerif;
  font-style: italic;
}

.katex .mainrm {
  font-family: KaTeX_Main;
  font-style: normal;
}

.katex .vlist-t {
  table-layout: fixed;
  border-collapse: collapse;
  display: inline-table;
}

.katex .vlist-r {
  display: table-row;
}

.katex .vlist {
  vertical-align: bottom;
  display: table-cell;
  position: relative;
}

.katex .vlist > span {
  height: 0;
  display: block;
  position: relative;
}

.katex .vlist > span > span {
  display: inline-block;
}

.katex .vlist > span > .pstrut {
  width: 0;
  overflow: hidden;
}

.katex .vlist-t2 {
  margin-right: -2px;
}

.katex .vlist-s {
  vertical-align: bottom;
  width: 2px;
  min-width: 2px;
  font-size: 1px;
  display: table-cell;
}

.katex .vbox {
  flex-direction: column;
  align-items: baseline;
  display: inline-flex;
}

.katex .hbox {
  flex-direction: row;
  width: 100%;
  display: inline-flex;
}

.katex .thinbox {
  flex-direction: row;
  width: 0;
  max-width: 0;
  display: inline-flex;
}

.katex .msupsub {
  text-align: left;
}

.katex .mfrac > span > span {
  text-align: center;
}

.katex .mfrac .frac-line {
  border-bottom-style: solid;
  width: 100%;
  display: inline-block;
}

.katex .mfrac .frac-line, .katex .overline .overline-line, .katex .underline .underline-line, .katex .hline, .katex .hdashline, .katex .rule {
  min-height: 1px;
}

.katex .mspace {
  display: inline-block;
}

.katex .llap, .katex .rlap, .katex .clap {
  width: 0;
  position: relative;
}

.katex .llap > .inner, .katex .rlap > .inner, .katex .clap > .inner {
  position: absolute;
}

.katex .llap > .fix, .katex .rlap > .fix, .katex .clap > .fix {
  display: inline-block;
}

.katex .llap > .inner {
  right: 0;
}

.katex .rlap > .inner, .katex .clap > .inner {
  left: 0;
}

.katex .clap > .inner > span {
  margin-left: -50%;
  margin-right: 50%;
}

.katex .rule {
  border: 0 solid;
  display: inline-block;
  position: relative;
}

.katex .overline .overline-line, .katex .underline .underline-line, .katex .hline {
  border-bottom-style: solid;
  width: 100%;
  display: inline-block;
}

.katex .hdashline {
  border-bottom-style: dashed;
  width: 100%;
  display: inline-block;
}

.katex .sqrt > .root {
  margin-left: .277778em;
  margin-right: -.555556em;
}

.katex .sizing.reset-size1.size1, .katex .fontsize-ensurer.reset-size1.size1 {
  font-size: 1em;
}

.katex .sizing.reset-size1.size2, .katex .fontsize-ensurer.reset-size1.size2 {
  font-size: 1.2em;
}

.katex .sizing.reset-size1.size3, .katex .fontsize-ensurer.reset-size1.size3 {
  font-size: 1.4em;
}

.katex .sizing.reset-size1.size4, .katex .fontsize-ensurer.reset-size1.size4 {
  font-size: 1.6em;
}

.katex .sizing.reset-size1.size5, .katex .fontsize-ensurer.reset-size1.size5 {
  font-size: 1.8em;
}

.katex .sizing.reset-size1.size6, .katex .fontsize-ensurer.reset-size1.size6 {
  font-size: 2em;
}

.katex .sizing.reset-size1.size7, .katex .fontsize-ensurer.reset-size1.size7 {
  font-size: 2.4em;
}

.katex .sizing.reset-size1.size8, .katex .fontsize-ensurer.reset-size1.size8 {
  font-size: 2.88em;
}

.katex .sizing.reset-size1.size9, .katex .fontsize-ensurer.reset-size1.size9 {
  font-size: 3.456em;
}

.katex .sizing.reset-size1.size10, .katex .fontsize-ensurer.reset-size1.size10 {
  font-size: 4.148em;
}

.katex .sizing.reset-size1.size11, .katex .fontsize-ensurer.reset-size1.size11 {
  font-size: 4.976em;
}

.katex .sizing.reset-size2.size1, .katex .fontsize-ensurer.reset-size2.size1 {
  font-size: .833333em;
}

.katex .sizing.reset-size2.size2, .katex .fontsize-ensurer.reset-size2.size2 {
  font-size: 1em;
}

.katex .sizing.reset-size2.size3, .katex .fontsize-ensurer.reset-size2.size3 {
  font-size: 1.16667em;
}

.katex .sizing.reset-size2.size4, .katex .fontsize-ensurer.reset-size2.size4 {
  font-size: 1.33333em;
}

.katex .sizing.reset-size2.size5, .katex .fontsize-ensurer.reset-size2.size5 {
  font-size: 1.5em;
}

.katex .sizing.reset-size2.size6, .katex .fontsize-ensurer.reset-size2.size6 {
  font-size: 1.66667em;
}

.katex .sizing.reset-size2.size7, .katex .fontsize-ensurer.reset-size2.size7 {
  font-size: 2em;
}

.katex .sizing.reset-size2.size8, .katex .fontsize-ensurer.reset-size2.size8 {
  font-size: 2.4em;
}

.katex .sizing.reset-size2.size9, .katex .fontsize-ensurer.reset-size2.size9 {
  font-size: 2.88em;
}

.katex .sizing.reset-size2.size10, .katex .fontsize-ensurer.reset-size2.size10 {
  font-size: 3.45667em;
}

.katex .sizing.reset-size2.size11, .katex .fontsize-ensurer.reset-size2.size11 {
  font-size: 4.14667em;
}

.katex .sizing.reset-size3.size1, .katex .fontsize-ensurer.reset-size3.size1 {
  font-size: .714286em;
}

.katex .sizing.reset-size3.size2, .katex .fontsize-ensurer.reset-size3.size2 {
  font-size: .857143em;
}

.katex .sizing.reset-size3.size3, .katex .fontsize-ensurer.reset-size3.size3 {
  font-size: 1em;
}

.katex .sizing.reset-size3.size4, .katex .fontsize-ensurer.reset-size3.size4 {
  font-size: 1.14286em;
}

.katex .sizing.reset-size3.size5, .katex .fontsize-ensurer.reset-size3.size5 {
  font-size: 1.28571em;
}

.katex .sizing.reset-size3.size6, .katex .fontsize-ensurer.reset-size3.size6 {
  font-size: 1.42857em;
}

.katex .sizing.reset-size3.size7, .katex .fontsize-ensurer.reset-size3.size7 {
  font-size: 1.71429em;
}

.katex .sizing.reset-size3.size8, .katex .fontsize-ensurer.reset-size3.size8 {
  font-size: 2.05714em;
}

.katex .sizing.reset-size3.size9, .katex .fontsize-ensurer.reset-size3.size9 {
  font-size: 2.46857em;
}

.katex .sizing.reset-size3.size10, .katex .fontsize-ensurer.reset-size3.size10 {
  font-size: 2.96286em;
}

.katex .sizing.reset-size3.size11, .katex .fontsize-ensurer.reset-size3.size11 {
  font-size: 3.55429em;
}

.katex .sizing.reset-size4.size1, .katex .fontsize-ensurer.reset-size4.size1 {
  font-size: .625em;
}

.katex .sizing.reset-size4.size2, .katex .fontsize-ensurer.reset-size4.size2 {
  font-size: .75em;
}

.katex .sizing.reset-size4.size3, .katex .fontsize-ensurer.reset-size4.size3 {
  font-size: .875em;
}

.katex .sizing.reset-size4.size4, .katex .fontsize-ensurer.reset-size4.size4 {
  font-size: 1em;
}

.katex .sizing.reset-size4.size5, .katex .fontsize-ensurer.reset-size4.size5 {
  font-size: 1.125em;
}

.katex .sizing.reset-size4.size6, .katex .fontsize-ensurer.reset-size4.size6 {
  font-size: 1.25em;
}

.katex .sizing.reset-size4.size7, .katex .fontsize-ensurer.reset-size4.size7 {
  font-size: 1.5em;
}

.katex .sizing.reset-size4.size8, .katex .fontsize-ensurer.reset-size4.size8 {
  font-size: 1.8em;
}

.katex .sizing.reset-size4.size9, .katex .fontsize-ensurer.reset-size4.size9 {
  font-size: 2.16em;
}

.katex .sizing.reset-size4.size10, .katex .fontsize-ensurer.reset-size4.size10 {
  font-size: 2.5925em;
}

.katex .sizing.reset-size4.size11, .katex .fontsize-ensurer.reset-size4.size11 {
  font-size: 3.11em;
}

.katex .sizing.reset-size5.size1, .katex .fontsize-ensurer.reset-size5.size1 {
  font-size: .555556em;
}

.katex .sizing.reset-size5.size2, .katex .fontsize-ensurer.reset-size5.size2 {
  font-size: .666667em;
}

.katex .sizing.reset-size5.size3, .katex .fontsize-ensurer.reset-size5.size3 {
  font-size: .777778em;
}

.katex .sizing.reset-size5.size4, .katex .fontsize-ensurer.reset-size5.size4 {
  font-size: .888889em;
}

.katex .sizing.reset-size5.size5, .katex .fontsize-ensurer.reset-size5.size5 {
  font-size: 1em;
}

.katex .sizing.reset-size5.size6, .katex .fontsize-ensurer.reset-size5.size6 {
  font-size: 1.11111em;
}

.katex .sizing.reset-size5.size7, .katex .fontsize-ensurer.reset-size5.size7 {
  font-size: 1.33333em;
}

.katex .sizing.reset-size5.size8, .katex .fontsize-ensurer.reset-size5.size8 {
  font-size: 1.6em;
}

.katex .sizing.reset-size5.size9, .katex .fontsize-ensurer.reset-size5.size9 {
  font-size: 1.92em;
}

.katex .sizing.reset-size5.size10, .katex .fontsize-ensurer.reset-size5.size10 {
  font-size: 2.30444em;
}

.katex .sizing.reset-size5.size11, .katex .fontsize-ensurer.reset-size5.size11 {
  font-size: 2.76444em;
}

.katex .sizing.reset-size6.size1, .katex .fontsize-ensurer.reset-size6.size1 {
  font-size: .5em;
}

.katex .sizing.reset-size6.size2, .katex .fontsize-ensurer.reset-size6.size2 {
  font-size: .6em;
}

.katex .sizing.reset-size6.size3, .katex .fontsize-ensurer.reset-size6.size3 {
  font-size: .7em;
}

.katex .sizing.reset-size6.size4, .katex .fontsize-ensurer.reset-size6.size4 {
  font-size: .8em;
}

.katex .sizing.reset-size6.size5, .katex .fontsize-ensurer.reset-size6.size5 {
  font-size: .9em;
}

.katex .sizing.reset-size6.size6, .katex .fontsize-ensurer.reset-size6.size6 {
  font-size: 1em;
}

.katex .sizing.reset-size6.size7, .katex .fontsize-ensurer.reset-size6.size7 {
  font-size: 1.2em;
}

.katex .sizing.reset-size6.size8, .katex .fontsize-ensurer.reset-size6.size8 {
  font-size: 1.44em;
}

.katex .sizing.reset-size6.size9, .katex .fontsize-ensurer.reset-size6.size9 {
  font-size: 1.728em;
}

.katex .sizing.reset-size6.size10, .katex .fontsize-ensurer.reset-size6.size10 {
  font-size: 2.074em;
}

.katex .sizing.reset-size6.size11, .katex .fontsize-ensurer.reset-size6.size11 {
  font-size: 2.488em;
}

.katex .sizing.reset-size7.size1, .katex .fontsize-ensurer.reset-size7.size1 {
  font-size: .416667em;
}

.katex .sizing.reset-size7.size2, .katex .fontsize-ensurer.reset-size7.size2 {
  font-size: .5em;
}

.katex .sizing.reset-size7.size3, .katex .fontsize-ensurer.reset-size7.size3 {
  font-size: .583333em;
}

.katex .sizing.reset-size7.size4, .katex .fontsize-ensurer.reset-size7.size4 {
  font-size: .666667em;
}

.katex .sizing.reset-size7.size5, .katex .fontsize-ensurer.reset-size7.size5 {
  font-size: .75em;
}

.katex .sizing.reset-size7.size6, .katex .fontsize-ensurer.reset-size7.size6 {
  font-size: .833333em;
}

.katex .sizing.reset-size7.size7, .katex .fontsize-ensurer.reset-size7.size7 {
  font-size: 1em;
}

.katex .sizing.reset-size7.size8, .katex .fontsize-ensurer.reset-size7.size8 {
  font-size: 1.2em;
}

.katex .sizing.reset-size7.size9, .katex .fontsize-ensurer.reset-size7.size9 {
  font-size: 1.44em;
}

.katex .sizing.reset-size7.size10, .katex .fontsize-ensurer.reset-size7.size10 {
  font-size: 1.72833em;
}

.katex .sizing.reset-size7.size11, .katex .fontsize-ensurer.reset-size7.size11 {
  font-size: 2.07333em;
}

.katex .sizing.reset-size8.size1, .katex .fontsize-ensurer.reset-size8.size1 {
  font-size: .347222em;
}

.katex .sizing.reset-size8.size2, .katex .fontsize-ensurer.reset-size8.size2 {
  font-size: .416667em;
}

.katex .sizing.reset-size8.size3, .katex .fontsize-ensurer.reset-size8.size3 {
  font-size: .486111em;
}

.katex .sizing.reset-size8.size4, .katex .fontsize-ensurer.reset-size8.size4 {
  font-size: .555556em;
}

.katex .sizing.reset-size8.size5, .katex .fontsize-ensurer.reset-size8.size5 {
  font-size: .625em;
}

.katex .sizing.reset-size8.size6, .katex .fontsize-ensurer.reset-size8.size6 {
  font-size: .694444em;
}

.katex .sizing.reset-size8.size7, .katex .fontsize-ensurer.reset-size8.size7 {
  font-size: .833333em;
}

.katex .sizing.reset-size8.size8, .katex .fontsize-ensurer.reset-size8.size8 {
  font-size: 1em;
}

.katex .sizing.reset-size8.size9, .katex .fontsize-ensurer.reset-size8.size9 {
  font-size: 1.2em;
}

.katex .sizing.reset-size8.size10, .katex .fontsize-ensurer.reset-size8.size10 {
  font-size: 1.44028em;
}

.katex .sizing.reset-size8.size11, .katex .fontsize-ensurer.reset-size8.size11 {
  font-size: 1.72778em;
}

.katex .sizing.reset-size9.size1, .katex .fontsize-ensurer.reset-size9.size1 {
  font-size: .289352em;
}

.katex .sizing.reset-size9.size2, .katex .fontsize-ensurer.reset-size9.size2 {
  font-size: .347222em;
}

.katex .sizing.reset-size9.size3, .katex .fontsize-ensurer.reset-size9.size3 {
  font-size: .405093em;
}

.katex .sizing.reset-size9.size4, .katex .fontsize-ensurer.reset-size9.size4 {
  font-size: .462963em;
}

.katex .sizing.reset-size9.size5, .katex .fontsize-ensurer.reset-size9.size5 {
  font-size: .520833em;
}

.katex .sizing.reset-size9.size6, .katex .fontsize-ensurer.reset-size9.size6 {
  font-size: .578704em;
}

.katex .sizing.reset-size9.size7, .katex .fontsize-ensurer.reset-size9.size7 {
  font-size: .694444em;
}

.katex .sizing.reset-size9.size8, .katex .fontsize-ensurer.reset-size9.size8 {
  font-size: .833333em;
}

.katex .sizing.reset-size9.size9, .katex .fontsize-ensurer.reset-size9.size9 {
  font-size: 1em;
}

.katex .sizing.reset-size9.size10, .katex .fontsize-ensurer.reset-size9.size10 {
  font-size: 1.20023em;
}

.katex .sizing.reset-size9.size11, .katex .fontsize-ensurer.reset-size9.size11 {
  font-size: 1.43981em;
}

.katex .sizing.reset-size10.size1, .katex .fontsize-ensurer.reset-size10.size1 {
  font-size: .24108em;
}

.katex .sizing.reset-size10.size2, .katex .fontsize-ensurer.reset-size10.size2 {
  font-size: .289296em;
}

.katex .sizing.reset-size10.size3, .katex .fontsize-ensurer.reset-size10.size3 {
  font-size: .337512em;
}

.katex .sizing.reset-size10.size4, .katex .fontsize-ensurer.reset-size10.size4 {
  font-size: .385728em;
}

.katex .sizing.reset-size10.size5, .katex .fontsize-ensurer.reset-size10.size5 {
  font-size: .433944em;
}

.katex .sizing.reset-size10.size6, .katex .fontsize-ensurer.reset-size10.size6 {
  font-size: .48216em;
}

.katex .sizing.reset-size10.size7, .katex .fontsize-ensurer.reset-size10.size7 {
  font-size: .578592em;
}

.katex .sizing.reset-size10.size8, .katex .fontsize-ensurer.reset-size10.size8 {
  font-size: .694311em;
}

.katex .sizing.reset-size10.size9, .katex .fontsize-ensurer.reset-size10.size9 {
  font-size: .833173em;
}

.katex .sizing.reset-size10.size10, .katex .fontsize-ensurer.reset-size10.size10 {
  font-size: 1em;
}

.katex .sizing.reset-size10.size11, .katex .fontsize-ensurer.reset-size10.size11 {
  font-size: 1.19961em;
}

.katex .sizing.reset-size11.size1, .katex .fontsize-ensurer.reset-size11.size1 {
  font-size: .200965em;
}

.katex .sizing.reset-size11.size2, .katex .fontsize-ensurer.reset-size11.size2 {
  font-size: .241158em;
}

.katex .sizing.reset-size11.size3, .katex .fontsize-ensurer.reset-size11.size3 {
  font-size: .281351em;
}

.katex .sizing.reset-size11.size4, .katex .fontsize-ensurer.reset-size11.size4 {
  font-size: .321543em;
}

.katex .sizing.reset-size11.size5, .katex .fontsize-ensurer.reset-size11.size5 {
  font-size: .361736em;
}

.katex .sizing.reset-size11.size6, .katex .fontsize-ensurer.reset-size11.size6 {
  font-size: .401929em;
}

.katex .sizing.reset-size11.size7, .katex .fontsize-ensurer.reset-size11.size7 {
  font-size: .482315em;
}

.katex .sizing.reset-size11.size8, .katex .fontsize-ensurer.reset-size11.size8 {
  font-size: .578778em;
}

.katex .sizing.reset-size11.size9, .katex .fontsize-ensurer.reset-size11.size9 {
  font-size: .694534em;
}

.katex .sizing.reset-size11.size10, .katex .fontsize-ensurer.reset-size11.size10 {
  font-size: .833601em;
}

.katex .sizing.reset-size11.size11, .katex .fontsize-ensurer.reset-size11.size11 {
  font-size: 1em;
}

.katex .delimsizing.size1 {
  font-family: KaTeX_Size1;
}

.katex .delimsizing.size2 {
  font-family: KaTeX_Size2;
}

.katex .delimsizing.size3 {
  font-family: KaTeX_Size3;
}

.katex .delimsizing.size4 {
  font-family: KaTeX_Size4;
}

.katex .delimsizing.mult .delim-size1 > span {
  font-family: KaTeX_Size1;
}

.katex .delimsizing.mult .delim-size4 > span {
  font-family: KaTeX_Size4;
}

.katex .nulldelimiter {
  width: .12em;
  display: inline-block;
}

.katex .delimcenter, .katex .op-symbol {
  position: relative;
}

.katex .op-symbol.small-op {
  font-family: KaTeX_Size1;
}

.katex .op-symbol.large-op {
  font-family: KaTeX_Size2;
}

.katex .op-limits > .vlist-t, .katex .accent > .vlist-t {
  text-align: center;
}

.katex .accent .accent-body {
  position: relative;
}

.katex .accent .accent-body:not(.accent-full) {
  width: 0;
}

.katex .overlay {
  display: block;
}

.katex .mtable .vertical-separator {
  min-width: 1px;
  display: inline-block;
}

.katex .mtable .arraycolsep {
  display: inline-block;
}

.katex .mtable .col-align-c > .vlist-t {
  text-align: center;
}

.katex .mtable .col-align-l > .vlist-t {
  text-align: left;
}

.katex .mtable .col-align-r > .vlist-t {
  text-align: right;
}

.katex .svg-align {
  text-align: left;
}

.katex svg {
  width: 100%;
  height: inherit;
  fill: currentColor;
  stroke: currentColor;
  fill-rule: nonzero;
  fill-opacity: 1;
  stroke-width: 1px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stroke-opacity: 1;
  display: block;
  position: absolute;
}

.katex svg path {
  stroke: none;
}

.katex img {
  border-style: none;
  min-width: 0;
  max-width: none;
  min-height: 0;
  max-height: none;
}

.katex .stretchy {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.katex .stretchy:before, .katex .stretchy:after {
  content: "";
}

.katex .hide-tail {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.katex .halfarrow-left {
  width: 50.2%;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.katex .halfarrow-right {
  width: 50.2%;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.katex .brace-left {
  width: 25.1%;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.katex .brace-center {
  width: 50%;
  position: absolute;
  left: 25%;
  overflow: hidden;
}

.katex .brace-right {
  width: 25.1%;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.katex .x-arrow-pad {
  padding: 0 .5em;
}

.katex .cd-arrow-pad {
  padding: 0 .55556em 0 .27778em;
}

.katex .x-arrow, .katex .mover, .katex .munder {
  text-align: center;
}

.katex .boxpad {
  padding: 0 .3em;
}

.katex .fbox, .katex .fcolorbox {
  box-sizing: border-box;
  border: .04em solid;
}

.katex .cancel-pad {
  padding: 0 .2em;
}

.katex .cancel-lap {
  margin-left: -.2em;
  margin-right: -.2em;
}

.katex .sout {
  border-bottom-style: solid;
  border-bottom-width: .08em;
}

.katex .angl {
  box-sizing: border-box;
  border-top: .049em solid;
  border-right: .049em solid;
  margin-right: .03889em;
}

.katex .anglpad {
  padding: 0 .03889em;
}

.katex .eqn-num:before {
  counter-increment: katexEqnNo;
  content: "(" counter(katexEqnNo) ")";
}

.katex .mml-eqn-num:before {
  counter-increment: mmlEqnNo;
  content: "(" counter(mmlEqnNo) ")";
}

.katex .mtr-glue {
  width: 50%;
}

.katex .cd-vert-arrow {
  display: inline-block;
  position: relative;
}

.katex .cd-label-left {
  text-align: left;
  display: inline-block;
  position: absolute;
  right: calc(50% + .3em);
}

.katex .cd-label-right {
  text-align: right;
  display: inline-block;
  position: absolute;
  left: calc(50% + .3em);
}

.katex-display {
  text-align: center;
  margin: 1em 0;
  display: block;
}

.katex-display > .katex {
  text-align: center;
  white-space: nowrap;
  display: block;
}

.katex-display > .katex > .katex-html {
  display: block;
  position: relative;
}

.katex-display > .katex > .katex-html > .tag {
  position: absolute;
  right: 0;
}

.katex-display.leqno > .katex > .katex-html > .tag {
  left: 0;
  right: auto;
}

.katex-display.fleqn > .katex {
  text-align: left;
  padding-left: 2em;
}

body {
  counter-reset: katexEqnNo mmlEqnNo;
}
/*# sourceMappingURL=index.fbd925f4.css.map */
